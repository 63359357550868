import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestModal: false,
  selectedServiceID: null,
};

export const modalsOpenerSlice = createSlice({
  name: "modalsOpener",
  initialState,
  reducers: {
    openRequestModal: (state) => {
      state.requestModal = true;
    },
    closeRequestModal: (state) => {
      state.requestModal = false;
      state.selectedServiceID = null;
    },
    openServiceModal: (state, action) => {
      state.requestModal = true;
      state.selectedServiceID = action.payload;
    },
  },
});

export const { openRequestModal, openServiceModal, closeRequestModal } =
  modalsOpenerSlice.actions;

export default modalsOpenerSlice.reducer;
