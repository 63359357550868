import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  show: false,
  type: "error",
};

export const globalMessageSlice = createSlice({
  name: "errorMessage",
  initialState,
  reducers: {
    setGlobalErrorMessage: (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.type = "error";
    },
    setGlobalNotificationMessage: (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.type = "notification";
    },
    clearMessage: (state) => {
      state.message = "";
    },
    clearShow: (state) => {
      state.show = false;
    },
  },
});

export const {
  setGlobalErrorMessage,
  setGlobalNotificationMessage,
  clearMessage,
  clearShow,
} = globalMessageSlice.actions;

export default globalMessageSlice.reducer;
