import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import Services from "./screens/Services";
import UserLayout from "./screens/UserLayout";
import Service from "./screens/Service";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { closeRequestModal } from "./redux/modalsOpenerSlice";
import pc from "./static/images/pc.png";
import laptop from "./static/images/laptop.png";
import console from "./static/images/console.png";
import axios from "axios";
import { api_url, media_url, gtag_report_conversion } from "./constants";
import { setGlobalErrorMessage } from "./redux/globalMessageSlice";
import { IoMdClose } from "react-icons/io";
import { GoCheckCircle } from "react-icons/go";
import FrequentlyAskedQuestions from "./screens/FrequentlyAskedQuestions";
import { useNavigate } from "react-router-dom";
import { setPageSEO, clearPageSEO } from "./redux/pageSEOSlice";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RemoteServices from "./screens/RemoteServices";
import RemoteService from "./screens/RemoteService";
import NotFound from "./screens/NotFound";
import GlobalMessage from "./components/GlobalMessage";
import AllServices from "./screens/AllServices";
function App() {
  const [isClosingModal, setIsClosingModal] = useState(false);
  const modalsOpener = useSelector((state) => state.modalsOpener);
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState("");
  const [description, setDescription] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [isPromoCodeVerifying, setIsPromoCodeVerifying] = useState(false);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [promoCodeData, setPromoCodeData] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isRequestSending, setIsRequestSending] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const location = useLocation();
  const language = useSelector((state) => state.language);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const page = location.pathname;

    axios
      .post(`${api_url}/seo/`, { page })
      .then((res) => {
        dispatch(setPageSEO(res.data));
      })
      .catch((err) => {
        dispatch(clearPageSEO());
      });
  }, [location]);

  useEffect(() => {
    navigate("?lang=" + language.toLowerCase());
    localStorage.setItem("language", language);
  }, [language]);

  function CloseModal() {
    setIsClosingModal(true);
    setTimeout(() => {
      dispatch(closeRequestModal());
      setIsClosingModal(false);
      setSelectedDevice("");
      setDescription("");
      setName("");
      setPhone("");
      setIsRequestSending(false);
      setIsRequestSent(false);
    }, 400);

    // setTimeout(() => {
    //   // setIsEmailConfirmationSending(false);
    //   // setIsEmailConfirmationSent(false);
    //   // setIsOTPConfirmationSending(false);
    //   // setIsSubscriptionConfirmed(false);
    //   // setIsPackRequestSending(false);
    //   // setIsPackRequestSent(false);
    //   // setIsEmailProvided(false);
    //   // setEmail("");
    //   // setOtp("");
    // }, 600);
  }

  function SendRequest(e) {
    e.preventDefault();

    if (isRequestSending) return;

    if (description.length < 10) {
      dispatch(
        setGlobalErrorMessage(
          "Descrierea trebuie să conțină cel puțin 10 caractere"
        )
      );
      return;
    }

    if (name.length < 4) {
      dispatch(
        setGlobalErrorMessage("Numele trebuie să conțină cel puțin 4 caractere")
      );
      return;
    }

    if (phone.length < 8) {
      dispatch(
        setGlobalErrorMessage(
          "Telefnul trebuie să conțină cel puțin 8 caractere"
        )
      );
      return;
    }

    setIsRequestSending(true);
    axios
      .post(api_url + "/request/", {
        device: selectedDevice.length > 0 ? selectedDevice : null,
        service: modalsOpener.selectedServiceID,
        promo: isPromoCodeValid ? promoCode : null,
        description,
        name,
        phone,
      })
      .then((res) => {
        setIsRequestSent(true);
        setIsRequestSending(false);
        setDescription("");
        setName("");
        setPhone("");
        gtag_report_conversion();
      })
      .catch((err) => {
        setIsRequestSending(false);
        dispatch(setGlobalErrorMessage("Ceva nu a mers bine"));
      });
  }
  const [service, setService] = useState(null);
  const [isServiceLoading, setIsServiceLoading] = useState(true);

  function VerifyPromoCode(code) {
    if (code.length < 4) {
      dispatch(
        setGlobalErrorMessage("Codul trebuie să conțină cel puțin 4 caractere")
      );
      return;
    }
    setIsPromoCodeVerifying(true);
    axios
      .post(api_url + "/promo/", { code })
      .then((res) => {
        setIsPromoCodeValid(true);
        setIsPromoCodeVerifying(false);
        setPromoCodeData(res.data);
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Codul promoțional este invalid"));
        setIsPromoCodeVerifying(false);
      });
  }

  useEffect(() => {
    if (modalsOpener.selectedServiceID) {
      setIsServiceLoading(true);
      axios
        .get(`${api_url}/services/${modalsOpener.selectedServiceID}/`)
        .then((res) => {
          setService(res.data);
          setIsServiceLoading(false);
        })
        .catch((err) => {
          setIsServiceLoading(false);
          dispatch(setGlobalErrorMessage("Ceva nu a mers bine"));
          setService(null);
        });
    } else {
      setService(null);
    }
  }, [modalsOpener.selectedServiceID]);

  return (
    <div>
      <Routes>
        <Route path="" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ru/kontakt" element={<Contact />} />
          <Route path="/servicii" element={<Services />} />
          <Route path="/ru/uslugi" element={<Services />} />
          <Route path="/servicii/:slug" element={<Service />} />
          <Route path="/ru/uslugi/:slug" element={<Service />} />
          <Route path="/servicii-la-distanta" element={<RemoteServices />} />
          <Route path="/toate-serviciile" element={<AllServices />} />
          <Route
            path="/ru/uslugi-na-rasstoyanii"
            element={<RemoteServices />}
          />
          <Route path="/404" element={<NotFound />} />
          <Route
            path="/servicii-la-distanta/:slug"
            element={<RemoteService />}
          />
          <Route
            path="/ru/uslugi-na-rasstoyanii/:slug"
            element={<RemoteService />}
          />
          <Route
            path="/politica-de-confidentialitate"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/ru/politika-konfidentsialnosti"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/intrebari-frecvente"
            element={<FrequentlyAskedQuestions />}
          />
          <Route
            path="/ru/chasto-zadavaemye-voprosy"
            element={<FrequentlyAskedQuestions />}
          />
        </Route>
      </Routes>
      {modalsOpener.requestModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isClosingModal ? 0 : 1 }}
          transition={{ duration: 0.2, delay: isClosingModal ? 0.2 : 0 }}
          onClick={CloseModal}
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-50"
        ></motion.div>
      )}
      {modalsOpener.requestModal && (
        <motion.div
          initial={{ opacity: 0 }}
          className=" fixed top-1/2 left-1/2 w-1/2 lg:w-3/4 xl:w-2/3 md:w-full sm:w-full bg-white z-50 rounded-md -translate-x-1/2 -translate-y-1/2 p-8"
          animate={{ opacity: isClosingModal ? 0 : 100 }}
          transition={{ duration: 0.3, delay: isClosingModal ? 0 : 0.2 }}
        >
          <button onClick={CloseModal} className="absolute top-4 right-4">
            <IoMdClose className="text-2xl" />
          </button>
          {(isRequestSent && (
            <div className="h-64 relative">
              <div className="w-fit absolute-center">
                <GoCheckCircle className="text-7xl mx-auto text-primary" />
                <p className="text-lg font-medium text-center mt-4">
                  Cererea a fost trimisă. Te vom contacta în cel mai scurt timp,
                </p>
              </div>
            </div>
          )) || (
            <form
              onSubmit={SendRequest}
              className=" grid grid-cols-2 sm:grid-cols-1 gap-y-8 gap-x-10"
            >
              <div>
                <h2 className="text-2xl font-bold">
                  {service ? "Serviciul selectat" : "Alege tipul de dispozitiv"}
                </h2>
                {service ? (
                  <div className="flex flex-col gap-y-1 mt-2">
                    <img
                      src={media_url + "/" + service?.images[0]}
                      alt={service?.name}
                      className="w-64 border-2 border-black border-solid rounded"
                    />
                    <p className="font-semibold text-lg">{service?.name}</p>
                  </div>
                ) : (
                  <div className="grid gap-x-8 grid-cols-3 w-full mt-2">
                    <button
                      disabled={isRequestSent || isRequestSending}
                      type="button"
                      onClick={() => {
                        setSelectedDevice("Calculator");
                      }}
                      className="flex flex-col gap-y-1"
                    >
                      <img
                        src={pc}
                        alt="pc"
                        className={
                          "border-solid p-3 border-2 rounded " +
                          (selectedDevice === "Calculator"
                            ? " border-primary"
                            : " border-secondary")
                        }
                      />
                      <p className="font-medium text-lg">Calculator</p>
                    </button>
                    <button
                      disabled={isRequestSent || isRequestSending}
                      type="button"
                      onClick={() => {
                        setSelectedDevice("Laptop");
                      }}
                      className="flex flex-col gap-y-1"
                    >
                      <img
                        src={laptop}
                        alt="laptop"
                        className={
                          "border-solid p-3 border-2 rounded " +
                          (selectedDevice === "Laptop"
                            ? " border-primary"
                            : " border-secondary")
                        }
                      />
                      <p className="font-medium text-lg">Laptop</p>
                    </button>
                    <button
                      disabled={isRequestSent || isRequestSending}
                      type="button"
                      onClick={() => {
                        setSelectedDevice("Console");
                      }}
                      className="flex flex-col gap-y-1"
                    >
                      <img
                        src={console}
                        alt="console"
                        className={
                          "border-solid p-3 border-2 rounded " +
                          (selectedDevice === "Console"
                            ? " border-primary"
                            : " border-secondary")
                        }
                      />
                      <p className="font-medium text-lg">Console</p>
                    </button>
                  </div>
                )}
                <h2 className="text-2xl font-bold mt-8">
                  Oferă-ne câteva detalii
                </h2>
                <textarea
                  disabled={isRequestSent || isRequestSending}
                  required
                  minLength={10}
                  className="w-full h-40 form-input mt-2"
                  placeholder="Descrie problema..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="">
                <h2 className="text-2xl font-bold">Informații de contact</h2>
                <input
                  disabled={isRequestSent || isRequestSending}
                  minLength={4}
                  required
                  type="text"
                  className="w-full form-input mt-2"
                  placeholder="Nume"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  disabled={isRequestSent || isRequestSending}
                  minLength={8}
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  className="w-full form-input mt-2"
                  placeholder="Număr de telefon"
                />
                <button
                  disabled={isRequestSent || isRequestSending}
                  className="bg-primary text-white font-semibold w-full text-lg py-2 mt-4 rounded-md px-4"
                >
                  {isRequestSending ? "Se trimite..." : "Trimite cererea"}
                </button>
                {isPromoCodeValid ? (
                  <p className="mt-2 font-medium">
                    Ați aplicat promo codul{" "}
                    <span className="text-primary">
                      {promoCodeData.description}.
                    </span>
                  </p>
                ) : (
                  <div className="relative w-full mt-2">
                    <input
                      disabled={isRequestSent || isRequestSending}
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      type="text"
                      className="w-full form-input"
                      placeholder="Promo Cod (dacă aveți)"
                    />
                    <button
                      onClick={() => VerifyPromoCode(promoCode)}
                      type="button"
                      disabled={isPromoCodeVerifying}
                      className="bg-primary absolute top-1/2 right-0 rounded-md py-0.5 px-4 border-1 border-black border-solid border-l-0 h-full -translate-y-1/2 text-white font-semibold "
                    >
                      Verifică
                    </button>
                  </div>
                )}
                <div className="mt-4 font-medium text-lg">
                  Sau sună-ne la{" "}
                  <a href="tel:+37360842266" className="text-primary">
                    060842266
                  </a>
                </div>
              </div>
            </form>
          )}
        </motion.div>
      )}
      <GlobalMessage />
    </div>
  );
}

export default App;
