import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import service1 from "../static/images/1.jpg";
import service2 from "../static/images/2.jpg";
import service3 from "../static/images/3.jpg";
// import popular1 from "../static/images/popular-1.jpg";
// import popular2 from "../static/images/popular-2.jpg";
// import popular3 from "../static/images/popular-3.jpg";
// import popular4 from "../static/images/popular-4.jpg";
// import popular5 from "../static/images/popular-5.jpg";
// import popular6 from "../static/images/popular-6.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MdExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { openRequestModal } from "../redux/modalsOpenerSlice";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";

const Home = () => {
  const dispatch = useDispatch();
  // const language = useSelector((state) => state.language);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios
      .get(`${api_url}/services/popular/`)
      .then((res) => {
        setServices(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const popularServices = [
  //   {
  //     image: popular1,
  //     title: "Repararea laptopurilor și calculatoarelor PC",
  //     url: "/servicii/repararea-laptopurilor-si-calculatoarelor-pc",
  //   },
  //   {
  //     image: popular2,
  //     title: "Repararea laptopurilor și calculatoarelor PC",
  //     url: "/servicii/repararea-laptopurilor-si-calculatoarelor-pc",
  //   },
  //   {
  //     image: popular3,
  //     title: "Repararea laptopurilor și calculatoarelor PC",
  //     url: "/servicii/repararea-laptopurilor-si-calculatoarelor-pc",
  //   },
  //   {
  //     image: popular4,
  //     title: "Repararea laptopurilor și calculatoarelor PC",
  //     url: "/servicii/repararea-laptopurilor-si-calculatoarelor-pc",
  //   },
  //   {
  //     image: popular5,
  //     title: "Repararea laptopurilor și calculatoarelor PC",
  //     url: "/servicii/repararea-laptopurilor-si-calculatoarelor-pc",
  //   },
  //   {
  //     image: popular6,
  //     title: "Repararea laptopurilor și calculatoarelor PC",
  //     url: "/servicii/repararea-laptopurilor-si-calculatoarelor-pc",
  //   },
  // ];

  const whyCooseUs = [
    {
      title: "Experiență în domeniu",
      title_ru: "Опыт в области",
      description:
        "Echipa noastră are o experiență vastă în reparația și întreținerea echipamentelor electronice. Aceștia sunt instruiți să lucreze cu cele mai noi tehnologii și să ofere soluții eficiente pentru orice problemă întâmpinată.",
      description_ru:
        "Наша команда имеет обширный опыт в ремонте и обслуживании электронного оборудования. Они обучены работать с последними технологиями и предлагать эффективные решения для любой проблемы.",
    },
    {
      title: "Varietate de servicii",
      title_ru: "Разнообразие услуг",
      description:
        "La JustFix, oferim servicii de reparație și profilaxie pentru calculatoare, console de jocuri și laptopuri. Acestea includ diagnosticare, înlocuire de componente, curățare, actualizări software, și optimizare a performanței.",
      description_ru:
        "В JustFix мы предлагаем услуги по ремонту и профилактике компьютеров, игровых консолей и ноутбуков. Они включают в себя диагностику, замену компонентов, чистку, обновление программного обеспечения и оптимизацию производительности.",
    },
    {
      title: "Proces rapid și eficient",
      title_ru: "Быстрый и эффективный процесс",
      description:
        "Vă preluăm echipamentul de la domiciuliu, îl diagnosticăm și reparam în cel mai scurt timp posibil. Vă ținem la curent cu progresul reparației și vă oferim un estimat detaliat al costurilor înainte de a începe lucrările.",
      description_ru:
        "Мы заберем ваше оборудование из дома, диагностируем и отремонтируем его в кратчайшие сроки. Мы будем держать вас в курсе прогресса ремонта и предоставим вам подробную оценку стоимости до начала работ.",
    },
  ];

  const frequentlyAskedQuestions = [
    {
      question: "Ce tipuri de servicii oferiți?",
      question_ru: "Какие виды услуг вы предлагаете?",
      answer:
        "La JustFix, oferim servicii de reparație și profilaxie pentru calculatoare, console de jocuri și laptopuri. Acestea includ diagnosticare, înlocuire de componente, curățare, actualizări software, și optimizare a performanței.",
      isOpened: false,
      answer_ru:
        "В JustFix мы предлагаем услуги по ремонту и профилактике компьютеров, игровых консолей и ноутбуков. Они включают в себя диагностику, замену компонентов, чистку, обновление программного обеспечения и оптимизацию производительности.",
    },
    {
      question: "Cum pot solicita o reparație?",
      question_ru: "Как я могу запросить ремонт?",
      answer: (
        <span>
          Pentru a solicita o reparație, te rugăm să ne contactezi prin apăsarea
          butonului{" "}
          <button
            onClick={() => dispatch(openRequestModal())}
            className="text-primary font-semibold"
          >
            Solicită o consultație
          </button>
          , telefonic, sau prin email. Vom aranja o preluare a echipamentului
          sau vom programa o vizită la domiciliul tău pentru diagnosticare și
          efectuarea reparației.
        </span>
      ),
      answer_ru: (
        <span>
          Чтобы запросить ремонт, пожалуйста, свяжитесь с нами, нажав на кнопку{" "}
          <button
            onClick={() => dispatch(openRequestModal())}
            className="text-primary font-semibold"
          >
            Запросить консультацию
          </button>
          , по телефону или по электронной почте. Мы организуем забор
          оборудования или назначим визит к вам домой для диагностики и ремонта.
        </span>
      ),
      isOpened: false,
    },
    {
      question: "Care sunt costurile asociate cu reparația?",
      question_ru: "Каковы затраты на ремонт?",
      answer:
        "Costul reparației variază în funcție de tipul și gravitatea problemei. După diagnosticarea echipamentului, îți vom oferi un estimat detaliat al costurilor înainte de a începe lucrările de reparație.",
      answer_ru:
        "Стоимость ремонта зависит от типа и серьезности проблемы. После диагностики оборудования мы предоставим вам подробную оценку стоимости до начала работ по ремонту.",
      isOpened: false,
    },
    {
      question: "Cât timp durează o reparație?",
      question_ru: "Как долго длится ремонт?",
      answer:
        "Timpul de reparație depinde de natura problemei și disponibilitatea pieselor de schimb. În general, majoritatea reparațiilor sunt finalizate într-un interval de 1-2 ore dacă venim la fața locului sau 1-2 zile dacă vă preluăm echipamentul. Te vom ține la curent cu progresul reparației.",
      answer_ru:
        "Время ремонта зависит от характера проблемы и наличия запасных частей. В целом, большинство ремонтов завершаются в течение 1-2 часов, если мы приезжаем на место, или 1-2 дней, если мы забираем ваше оборудование. Мы будем держать вас в курсе прогресса ремонта.",

      isOpened: false,
    },
    {
      question: "Oferiți garanție pentru reparații?",
      question_ru: "Вы предоставляете гарантию на ремонт?",
      answer:
        "Da, oferim garanție pentru toate reparațiile efectuate. Garanția acoperă atât piesele înlocuite, cât și manopera, conform termenilor și condițiilor specificate. Detalii suplimentare sunt disponibile la cerere.",
      answer_ru:
        "Да, мы предоставляем гарантию на все выполненные ремонты. Гарантия распространяется как на замененные детали, так и на работу, в соответствии с указанными условиями. Дополнительные сведения доступны по запросу.",
      isOpened: false,
    },
    {
      question: "Oferiți servicii de la distanță?",
      question_ru: "Вы предоставляете удаленные услуги?",
      answer:
        "Da, oferim anumite servicii de la distanță pentru echipamentele IT. Acestea includ diagnosticare, configurare, și asistență tehnică online pentru a rezolva problemele fără a fi nevoie de prezența fizică a echipamentului.",
      answer_ru:
        "Да, мы предлагаем определенные удаленные услуги для IT-оборудования. Они включают в себя диагностику, настройку и онлайн-техническую поддержку для решения проблем без необходимости физического присутствия оборудования.",
      isOpened: false,
    },
  ];

  const [faqs, setFaqs] = useState(frequentlyAskedQuestions);
  const pageSEO = useSelector((state) => state.pageSEO);

  const primaryServices = [
    {
      title: "Repararea laptopurilor și calculatoarelor PC",
      title_ru: "Ремонт ноутбуков и ПК",
      description: (
        <div className="flex flex-col gap-y-2">
          <p>
            Oferim servicii de reparație pentru toate modelele de laptopuri și
            calculatoare PC, indiferent de configurația lor. Fie că ai nevoie de
            reparații sau de o întreținere periodică, specialiștii noștri pot
            efectua aceste servicii.
          </p>
          <p>
            Pentru mai multe informații despre serviciile noastre, te invităm să
            vizitezi serviciile Calculatoare și Laptopuri, sau poți solicita o
            consultație gratuită prin simpla apăsare a butonului de mai jos.
          </p>
        </div>
      ),
      description_ru: (
        <div className="flex flex-col gap-y-2">
          <p>
            Мы предлагаем услуги по ремонту всех моделей ноутбуков и ПК,
            независимо от их конфигурации. Независимо от того, нужен ли вам
            ремонт или периодическое обслуживание, наши специалисты могут
            предоставить эти услуги.
          </p>
          <p>
            Для получения дополнительной информации о наших услугах, мы
            приглашаем вас посетить страницу Услуги по ремонту компьютеров и
            ноутбуков, или вы можете запросить бесплатную консультацию, просто
            нажав на кнопку ниже.
          </p>
        </div>
      ),
      image: service1,
    },
    {
      title: "Profilaxie și reparare pentru console de jocuri",
      description: (
        <div className="flex flex-col gap-y-2">
          <p>
            Oferim servicii complete de profilaxie și reparație pentru orice
            model de consolă de jocuri, indiferent de brand sau specificații.
            Echipa noastră de specialiști se ocupă de diagnosticarea și
            remedierea problemelor tehnice, asigurându-se că dispozitivul tău
            funcționează la parametri optimi. Fie că este vorba de o reparație
            urgentă sau de o întreținere preventivă, intervențiile noastre pot
            fi realizate în cel mai scurt timp posibil.
          </p>
          <p>
            Pentru detalii suplimentare despre acest serviciu, poți vizita
            serviciul dedicat Consolelor sau solicita o consultație gratuită
            apăsând butonul de mai jos.
          </p>
        </div>
      ),
      image: service2,
    },
    {
      title: "Curățarea și profilaxia laptopurilor și calculatoarelor",
      description: (
        <div className="flex flex-col gap-y-2">
          <p>
            Oferim servicii profesionale de curățare și depănare software pentru
            toate modelele de calculatoare și laptopuri. Fie că te confrunți cu
            probleme de performanță, viruși sau erori de sistem, specialiștii
            noștri vor identifica și rezolva rapid orice dificultate software,
            asigurându-se că dispozitivul tău funcționează optim și fără
            întreruperi. De asemenea, ne ocupăm de curățarea sistemului de
            fișiere inutile și optimizarea generală a performanței.
          </p>
          <p>
            Pentru mai multe detalii, poți explora serviciul Calculatoare și
            Laptopuri sau solicita o consultație gratuită prin apăsarea
            butonului de mai jos.
          </p>
        </div>
      ),
      image: service3,
    },
  ];

  return (
    <div>
      <div className="mb-20  my-12">
        <Helmet>
          <title>{pageSEO.title}</title>
          <meta name="description" content={pageSEO.description} />
          <meta name="keywords" content={pageSEO.keywords} />
          <link rel="canonical" href="https://justfix.md/" />
        </Helmet>
        <div className="px-72 xl:px-48 lg:px-20 md:px-10 sm:px-0">
          <Carousel swipeable={false}>
            {primaryServices.map((service, index) => (
              <div
                key={"service-" + index}
                className="grid grid-cols-[45%,55%] md:grid-cols-1 gap-y-8 sm:grid-cols-1 gap-x-8 w-full bg-white px-8"
              >
                <div className="flex flex-col text-left gap-y-4">
                  <h2 className="font-semibold text-2xl">{service.title}</h2>
                  <p>{service.description}</p>
                  <button
                    onClick={() => {
                      dispatch(openRequestModal());
                    }}
                    className="bg-primary text-white font-semibold text-lg py-2 w-fit mt-4 rounded-md px-4"
                  >
                    Solicită o consultație gratuită
                  </button>
                </div>
                <img
                  src={service.image}
                  className="rounded-lg shadow-md shadow-black/35"
                  alt={`service-${index + 1}`}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="px-80 mt-16 xl:px-56 lg:px-24 md:px-12 sm:px-6">
          <div className="flex justify-between items-baseline">
            <h2 className="font-semibold text-3xl sm:text-2xl">
              Cele mai solicitate servicii
            </h2>
            <Link to={"/servicii"} className="text-primary text-xl font-medium">
              Vezi toate
            </Link>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-8 mt-4 gap-y-8">
            {isLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <div
                    key={index}
                    className="rounded-md shadow-md shadow-black/35 border-2 border-solid group-hover:border-primary border-transparent transition-colors ease-in-out duration-200"
                  >
                    <Skeleton height={200} />
                    <Skeleton height={20} />
                  </div>
                ))
              : services.map((service, index) => (
                  <Link
                    to={
                      service.is_remote
                        ? "/servicii-la-distanta/" + service.slug
                        : "/servicii/" + service.slug
                    }
                    key={index}
                    className="flex flex-col gap-y-2 group"
                  >
                    <img
                      src={media_url + service.image}
                      className="rounded-md shadow-md shadow-black/35 border-2 border-solid group-hover:border-primary border-transparent transition-colors ease-in-out duration-200"
                      alt={`popular-${index + 1}`}
                    />
                    <h3 className="font-medium text-lg text-black/75">
                      {service.name}
                    </h3>
                  </Link>
                ))}
          </div>
          <h2 className="font-semibold text-3xl sm:text-2xl mt-16">
            De ce să alegi JustFix?
          </h2>
          <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-8 mt-4 gap-y-8">
            {whyCooseUs.map((reason, index) => (
              <motion.div
                key={index}
                whileHover={{ y: -4, borderBottomColor: "#D00124" }}
                className="h-full gap-y-4 p-4 flex flex-col bg-black/10 border-b-transparent border-b-2 border-b-solid rounded-md"
              >
                <h3 className="text-xl font-medium">{reason.title}</h3>
                <p className="mt-auto">{reason.description}</p>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-between items-baseline">
            <h2 className="font-semibold text-3xl mt-16 sm:text-2xl">
              Întrebări frecvente
            </h2>
            <Link
              to={"/intrebari-frecvente"}
              className="text-primary text-xl font-medium"
            >
              Vezi toate
            </Link>
          </div>
          <div className="flex flex-col gap-y-1 mt-1">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={
                  "flex flex-col border-b-2 transition-colors ease-in-out duration-300 border-b-solid" +
                  (!faq.isOpened
                    ? " border-transparent"
                    : " border-primary delay-100")
                }
              >
                <button
                  onClick={() => {
                    setFaqs((prevFaqs) =>
                      prevFaqs.map((prevFaq, prevIndex) =>
                        prevIndex === index
                          ? { ...prevFaq, isOpened: !prevFaq.isOpened }
                          : prevFaq
                      )
                    );
                  }}
                  className="flex justify-between items-center py-4"
                >
                  <h3 className="text-xl font-medium text-left">
                    {faq.question}
                  </h3>

                  <MdExpandMore
                    className={`transition-transform text-xl ease-in-out duration-150 ${
                      faq.isOpened ? "transform rotate-180" : ""
                    } text-black/75`}
                  />
                </button>
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: faq.isOpened ? "auto" : 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  <p className="pb-2 text-lg">{faq.answer}</p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
