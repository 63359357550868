import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
const Services = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const language = useSelector((state) => state.language);
  const pageSEO = useSelector((state) => state.pageSEO);

  useEffect(() => {
    axios.get(`${api_url}/services/`).then((res) => {
      const services = res.data;
      const categories = res.data.map((service) => service.category);
      //   {
      //     name, slug, id
      //   }
      const uniqueCategories = Array.from(
        new Set(categories.map((category) => category.id))
      ).map((id) => {
        return {
          category: categories.find((category) => category.id === id),
          services: services.filter((service) => service.category.id === id),
        };
      });
      setCategories(uniqueCategories);

      setIsLoading(false);
    });
  }, []);

  return (
    <div className="px-80 flex flex-col gap-y-8 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{pageSEO.title}</title>
        <meta name="description" content={pageSEO.description} />
        <meta name="keywords" content={pageSEO.keywords} />
        <link rel="canonical" href="https://justfix.md/servicii" />
      </Helmet>
      <div className="flex flex-col gap-y-8">
        {isLoading
          ? Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="">
                <h2 className="text-2xl">
                  <Skeleton height={50} />
                </h2>
                <div className="grid grid-cols-3 gap-y-4 mt-4">
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                </div>
              </div>
            ))
          : categories.map((category) => (
              <div>
                <h2 key={category.category.name} className="text-2xl">
                  {category.category.name}
                </h2>
                <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 gap-y-4 mt-2">
                  {category.services.map((service, index) => (
                    <Link
                      to={service.slug + ""}
                      key={index}
                      className="flex flex-col gap-y-2 group"
                    >
                      <img
                        src={media_url + service.image}
                        className="rounded-md shadow-md shadow-black/35 border-2 border-solid group-hover:border-primary border-transparent transition-colors ease-in-out duration-200"
                        alt={`popular-${index + 1}`}
                      />
                      <h3 className="font-medium text-lg text-black/75">
                        {service.name}
                      </h3>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Services;
