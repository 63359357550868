import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import axios from "axios";
import { api_url } from "../constants";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const AllServices = () => {
  const pageSEO = useSelector((state) => state.pageSEO);

  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  useEffect(() => {
    axios
      .get(`${api_url}/services/all/`)
      .then((res) => {
        setServices(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="px-80 flex flex-col gap-y-6 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{pageSEO.title}</title>
        <meta name="description" content={pageSEO.description} />
        <meta name="keywords" content={pageSEO.keywords} />
        <link rel="canonical" href="https://justfix.md/servicii" />
      </Helmet>
      <h1 className="font-semibold text-3xl">Toate Serviciile</h1>
      <div className="flex flex-col gap-y-4">
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} height={40} />
            ))
          : services.map((service) => (
              <div
                key={service.id}
                className="border-b-solid border-b-2 border-b-black pb-2"
              >
                <Link
                  to={
                    (service.is_remote
                      ? "/servicii-la-distanta/"
                      : "/servicii/") + service.slug
                  }
                >
                  <h2 className="text-lg">{service.name}</h2>
                </Link>
              </div>
            ))}
      </div>
    </div>
  );
};

export default AllServices;
