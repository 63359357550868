import React from "react";

const NotFound = () => {
  return (
    <div className="px-80 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <h1 className="text-xl font-medium bg-black/10 w-full py-6 text-center ">
        404 - Pagina nu a fost găsită
      </h1>
    </div>
  );
};

export default NotFound;
