import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import anydesk from "../static/images/anydesk.png";
import secretCode from "../static/images/secret-code.png";
import wait from "../static/images/wait.png";
const RemoteServices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const language = useSelector((state) => state.language);
  const pageSEO = useSelector((state) => state.pageSEO);

  useEffect(() => {
    axios.get(`${api_url}/services/remote/`).then((res) => {
      const services = res.data;
      const categories = res.data.map((service) => service.category);
      //   {
      //     name, slug, id
      //   }
      const uniqueCategories = Array.from(
        new Set(categories.map((category) => category.id))
      ).map((id) => {
        const servicesList = services.filter(
          (service) => service.category.id === id
        );
        if (servicesList.length > 0) {
          return {
            category: categories.find((category) => category.id === id),
            services: servicesList,
          };
        }
      });
      setCategories(uniqueCategories);

      setIsLoading(false);
    });
  }, []);

  return (
    <div className="px-80 flex flex-col gap-y-8 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{pageSEO.title}</title>
        <meta name="description" content={pageSEO.description} />
        <meta name="keywords" content={pageSEO.keywords} />
        <link rel="canonical" href="https://justfix.md/servicii-la-distanta" />
      </Helmet>
      <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-4 ">
        <div className="flex relative flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={anydesk} alt={"anydesk-icon"} className="w-20" />

          <p className="text-black/75 text-center mt-4">
            Folosim Anydesk pentru a oferi suport tehnic la distanță. Descarcă
            și instalează aplicația.
          </p>
          <a href="https://anydesk.com/en/downloads/windows" target="_blank">
            <button className="bg-primary text-sm absolute top-4 right-4 text-white font-medium rounded-full px-8 py-2">
              Descarcă
            </button>
          </a>
        </div>
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={secretCode} alt={"anydesk-id"} className="w-20" />

          <p className="text-black/75 text-center mt-4">
            Oferă operatorului tău Anydesk ID-ul generat pentru a începe
            sesiunea de suport.
          </p>
        </div>
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={wait} alt={"person-waiting"} className="w-20" />

          <p className="text-black/75 text-center mt-4">
            Așteaptă conectarea operatorului și urmează instrucțiunile pentru a
            primi suport tehnic.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-8">
        {isLoading
          ? Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="">
                <h2 className="text-2xl">
                  <Skeleton height={50} />
                </h2>
                <div className="grid grid-cols-3 gap-y-4 mt-4">
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                </div>
              </div>
            ))
          : categories.map((category) => (
              <div>
                <h2 key={category.category.name} className="text-2xl">
                  {category.category.name}
                </h2>
                <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 gap-y-4 mt-2">
                  {category.services.map((service, index) => (
                    <Link
                      to={service.slug + ""}
                      key={index}
                      className="flex flex-col gap-y-2 group"
                    >
                      <img
                        src={media_url + service.image}
                        className="rounded-md shadow-md shadow-black/35 border-2 border-solid group-hover:border-primary border-transparent transition-colors ease-in-out duration-200"
                        alt={`popular-${index + 1}`}
                      />
                      <h3 className="font-medium text-lg text-black/75">
                        {service.name}
                      </h3>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default RemoteServices;
