import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { api_url, defaultPageSEO, media_url } from "../constants";
import { useParams, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import step1 from "../static/images/step-1.svg";
import step2 from "../static/images/step-2.svg";
import step3 from "../static/images/step-3.svg";
import step4 from "../static/images/step-4.svg";
import { useDispatch } from "react-redux";
import { openServiceModal } from "../redux/modalsOpenerSlice";
import { Helmet } from "react-helmet-async";
const RemoteService = () => {
  const { slug } = useParams();
  const [service, setService] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const workingSteps = [
    {
      id: 1,
      image: step1,
      title: "Diagnostica",
      description:
        "Conectarea de la distanță la echipamentul dumneavoastră și stabilirea soluției optime pentru efectuarea lucrărilor.",
    },
    {
      id: 2,
      image: step2,
      title: "Plata serviciilor",
      description:
        "Efectuarea plății pentru serviciile ce urmează a fi prestate.",
    },
    {
      id: 3,
      image: step3,
      title: "Efectuarea lucrărilor",
      description:
        "Efectuarea lucrărilor de la distanță pentru soluționarea problemei.",
    },
    {
      id: 4,
      image: step4,
      title: "Confirmarea și Garanția",
      description:
        "Confirmarea finalizării lucrărilor și oferirea garanției pentru serviciile prestate.",
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${api_url}/services/${slug}/remote/`)
      .then((res) => {
        setService(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/404");
      });
  }, [slug]);

  return (
    <div className="px-80 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{service ? service.seo_title : defaultPageSEO.title}</title>
        <meta
          name="description"
          content={
            service ? service.seo_description : defaultPageSEO.description
          }
        />
        <meta
          name="keywords"
          content={service ? service.seo_keywords : defaultPageSEO.keywords}
        />
        <link
          rel="canonical"
          href={`https://justfix.md/servicii-la-distanta/${slug}`}
        />
      </Helmet>
      {(isLoading && <Skeleton height={20} width={"50%"} />) || (
        <div className="flex gap-x-2 sm:flex-wrap items-center text-lg font-medium">
          <Link to="/" className="text-primary">
            Acasă
          </Link>
          <IoMdArrowDropright />
          <Link to="/servicii-la-distanta" className="text-primary">
            Servicii la distanță
          </Link>
          <IoMdArrowDropright />
          <p>{service?.name}</p>
        </div>
      )}
      {isLoading ? (
        <div className="grid grid-cols-2 gap-x-8 mt-6">
          <div>
            <Skeleton height={400} />
          </div>
          <div>
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-x-8 mt-8">
          <div>
            <Carousel>
              {service.images.map((image, index) => (
                <img key={index} src={`${media_url}/${image}`} />
              ))}
            </Carousel>
          </div>
          <div>
            <h2 className="text-3xl font-semibold">{service?.name}</h2>
            <p className="rounded bg-primary py-1 px-4 mt-1 text-sm w-fit text-white font-medium">
              Serviciu prin conectare de la distanță
            </p>
            <div className="flex flex-col gap-y-2 mt-4 text-black/75 mb-4">
              {service?.description.split("\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
            <button
              onClick={() => {
                dispatch(openServiceModal(service.id));
              }}
              className="rounded-md bg-primary mt-auto px-8 ml-auto text-white text-lg font-medium py-2 block"
            >
              Solicită serviciul
            </button>
          </div>
        </div>
      )}
      <div className="mt-8">
        <h2 className="text-3xl font-semibold">Etapele de lucru</h2>
        <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 gap-x-8 gap-y-4 mt-4">
          {workingSteps.map((step, index) => (
            <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
              <img src={step.image} alt={"step-" + index} className="w-20" />
              <h3 className="text-lg font-medium mt-4">{step.title}</h3>
              <p className="text-black/75 text-center text-sm">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RemoteService;
