import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage, clearShow } from "../redux/globalMessageSlice";
import { motion } from "framer-motion";
const GlobalMessage = () => {
  const globalMessage = useSelector((state) => state.globalMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (globalMessage.show) {
      setTimeout(() => {
        dispatch(clearShow());
        setTimeout(() => {
          dispatch(clearMessage());
        }, 500);
      }, 3000);
    }
  }, [globalMessage.show]);

  return (
    <motion.div
      initial={{ x: "125%" }}
      animate={{ x: globalMessage.show ? 0 : "125%" }}
      exit={{ x: "125%" }}
      transition={{ duration: 0.5 }}
      className={
        " px-8 fixed bottom-8 right-4 z-50 py-2 font-semibold drop-shadow-lg rounded-md " +
        (globalMessage.type === "error"
          ? "bg-primary text-white"
          : "text-secondary bg-white")
      }
    >
      {globalMessage.message}
    </motion.div>
  );
};

export default GlobalMessage;
