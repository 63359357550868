import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("language") || "RO";

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
