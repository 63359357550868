import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="px-80 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <h1 className="font-semibold text-3xl">Politica de Confidențialitate</h1>
      <h2 className="mt-2 text-xl font-medium">Protecția Datelor Personale</h2>
      <p>
        Prelucrăm datele conform GDPR și legislației din Republica Moldova. Ne
        asigurăm de transparență și vă informăm despre modul de colectare,
        utilizare și protejare a datelor personale.
      </p>
      <h2 className="mt-2 text-xl font-medium">Contact</h2>
      <p>
        Pentru întrebări legate de datele dvs., ne puteți contacta la
        office@justfix.md.
      </p>
      <h2 className="mt-2 text-xl font-medium">Date Colectate</h2>
      <p>
        Colectăm date furnizate direct de dvs. prin intermediul formularelor de
        contact. Acestea includ nume, prenume, adresă de email, număr de
        telefon.
      </p>
      <h2 className="mt-2 text-xl font-medium">Scopul Colectării</h2>
      <p>
        Colectăm datele pentru a vă oferi informații despre serviciile noastre
        și pentru a răspunde la întrebările dvs. Nu folosim datele în alte
        scopuri.
      </p>
      <h2 className="mt-2 text-xl font-medium">Ștergerea Datelor</h2>
      <p>
        Puteți solicita ștergerea datelor personale prin email la
        office@justfix.md
      </p>
      <h2 className="mt-2 text-xl font-medium">Cine Primește Datele</h2>
      <p>
        Datele pot fi transmise partenerilor (curierat, IT, bancar etc.) și
        autorităților, dacă este necesar.
      </p>
      <h2 className="mt-2 text-xl font-medium">Securitatea Datelor</h2>
      <p>
        Datele sunt stocate pe servere securizate și sunt protejate conform
        standardelor de securitate.
      </p>
      <h2 className="mt-2 text-xl font-medium">Modificări</h2>
      <p>
        Politica de confidențialitate poate fi modificată. Vă vom informa despre
        orice modificare.
      </p>
      <h2 className="mt-2 text-xl font-medium">Drepturile Dvs.</h2>
      <p>
        Aveți dreptul de a accesa, modifica sau șterge datele personale. Puteți
        solicita informații despre datele dvs. la adresa de email
        office@justfix.md.
      </p>
      <h2 className="mt-2 text-xl font-medium">Ultima Actualizare</h2>
      <p>Ultima actualizare: 01.01.2022</p>
    </div>
  );
};

export default PrivacyPolicy;
