import { createSlice } from "@reduxjs/toolkit";
import { defaultPageSEO } from "../constants";
const initialState = defaultPageSEO;

export const pageSEOSlice = createSlice({
  name: "pageSEO",
  initialState,
  reducers: {
    setPageSEO: (state, action) => {
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.keywords = action.payload.keywords;
      state.title_ru = action.payload.title_ru;
      state.description_ru = action.payload.description_ru;
      state.keywords_ru = action.payload.keywords_ru;
    },
    clearPageSEO: (state) => {
      state.title = defaultPageSEO.title;
      state.description = defaultPageSEO.description;
      state.keywords = defaultPageSEO.keywords;
      state.title_ru = defaultPageSEO.title_ru;
      state.description_ru = defaultPageSEO.description_ru;
      state.keywords_ru = defaultPageSEO.keywords_ru;
    },
  },
});

export const { setPageSEO, clearPageSEO } = pageSEOSlice.actions;

export default pageSEOSlice.reducer;
