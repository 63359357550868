import React, { useState } from "react";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import { GoCheckCircle } from "react-icons/go";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
const Contact = () => {
  const purposes = [
    { id: 1, name: "Solicitare de informații", name_ru: "Запрос информации" },
    { id: 2, name: "Reclamații", name_ru: "Жалобы" },
    { id: 3, name: "Sugestii", name_ru: "Предложения" },
    {
      id: 4,
      name: "Propunere de colaborare",
      name_ru: "Предложение о сотрудничестве",
    },
    { id: 5, name: "Altele", name_ru: "Другое" },
  ];
  const dispatch = useDispatch();
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isSentError, setIsSentError] = useState(false);
  function SendContact(e) {
    e.preventDefault();

    if (isSending) return;

    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!name || !email || !message || !selectedPurpose) {
      dispatch(
        setGlobalErrorMessage(
          language == "RO"
            ? "Toate câmpurile sunt obligatorii"
            : "Все поля обязательны"
        )
      );
      return;
    }

    if (email.length < 6) {
      dispatch(
        setGlobalErrorMessage(
          language == "RO"
            ? "Email-ul trebuie să conțină cel puțin 6 caractere"
            : "Email должен содержать не менее 6 символов"
        )
      );
      return;
    }

    if (message.length < 10) {
      dispatch(
        setGlobalErrorMessage(
          language == "RO"
            ? "Mesajul trebuie să conțină cel puțin 10 caractere"
            : "Сообщение должно содержать не менее 10 символов"
        )
      );
      return;
    }

    setIsSending(true);
    setIsSentError(false);

    axios
      .post(`${api_url}/contact/`, {
        name,
        email,
        message,
        purpose: selectedPurpose,
      })
      .then((response) => {
        setIsSending(false);
        setIsSent(true);
        e.target.reset();
        setSelectedPurpose(null);
      })
      .catch((error) => {
        setIsSending(false);
        setIsSentError(true);
      });
  }
  const pageSEO = useSelector((state) => state.pageSEO);
  const language = useSelector((state) => state.language);

  return (
    <div className="mb-20 mt-12">
      <Helmet>
        <title>{language == "RO" ? pageSEO.title : pageSEO.title_ru}</title>
        <meta
          name="description"
          content={
            language == "RO" ? pageSEO.description : pageSEO.ru_description
          }
        />
        <meta
          name="keywords"
          content={language == "RO" ? pageSEO.keywords : pageSEO.ru_keywords}
        />
        <link rel="canonical" href={`https://justfix.com/contact`} />
      </Helmet>
      <form
        onSubmit={SendContact}
        className="px-80  gap-x-8 xl:px-56 lg:px-24 md:px-12 sm:px-6 grid grid-cols-2 sm:grid-cols-1 gap-y-6"
      >
        <div>
          <h2 className="font-semibold text-3xl">
            {language == "RO"
              ? "Alege scopul contactării"
              : "Выберите цель обращения"}
          </h2>
          <div className="flex flex-col gap-y-4 mt-2">
            {purposes.map((purpose) => (
              <button
                type="button"
                key={purpose.id}
                className={`rounded-md border-primary text-left px-4 text-lg  transition-colors ease-in-out duration-200 font-medium py-2 border-2 border-solid ${
                  selectedPurpose === purpose.id
                    ? "bg-primary text-white"
                    : "text-primary hover:bg-primary/10"
                }`}
                onClick={() => setSelectedPurpose(purpose.id)}
              >
                {language == "RO" ? purpose.name : purpose.name_ru}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h1 className="font-semibold text-3xl">
            {language == "RO" ? "Completează formularul" : "Заполните форму"}
          </h1>
          <div className="flex flex-col gap-y-4 mt-2">
            <input
              disabled={isSending || isSent}
              name="name"
              placeholder={language == "RO" ? "Nume Prenume" : "Имя Фамилия"}
              className="form-input"
            />
            <input
              disabled={isSending || isSent}
              name="email"
              placeholder={
                language == "RO"
                  ? "Email sau Număr de Telefon"
                  : "Электронная почта или номер телефона"
              }
              className="form-input"
            />
            <textarea
              disabled={isSending || isSent}
              name="message"
              placeholder={language == "RO" ? "Mesaj" : "Сообщение"}
              className="form-input h-28 resize-none"
            ></textarea>
            <button
              disabled={isSending || isSent}
              type="submit"
              className="rounded-md bg-primary text-white text-lg font-medium py-2"
            >
              {isSending
                ? language == "RO"
                  ? "Se trimite..."
                  : "Отправляется..."
                : language == "RO"
                ? "Trimite"
                : "Отправить"}
            </button>
          </div>
        </div>
      </form>
      {isSent && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: isSent ? "auto" : 0 }}
          className="flex items-center overflow-y-auto scrollbar-none w-full gap-x-2"
        >
          <div className="gap-y-4 text-center w-full pb-12 mt-20 sm:mt-10 px-8 flex flex-col">
            <GoCheckCircle className="text-primary w-fit mx-auto text-7xl" />
            <p className="text-primary font-medium text-lg">
              {language == "RO"
                ? "Mesajul a fost trimis. Îl vom analiza și te vom contacta în cel mai surt timp posibil."
                : "Сообщение отправлено. Мы рассмотрим его и свяжемся с вами в кратчайшие сроки."}
            </p>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Contact;
